<template>
    <div class="su-wrapper">
        <nh-title title="护士编辑"></nh-title>
        <div class="su-container">
            <div class="survey_content_line nurse_content_line">
                <div class="nurse_label">区域</div>
                <el-select class="nurse_value" v-model="nurse.areaIds" multiple placeholder="区域" clearable @change="doChange">
                    <el-option
                            v-for="item in areaList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="survey_content_line nurse_content_line">
                <div class="nurse_label">驻点医院</div>
                <el-input class="nurse_value" type="textarea"
                          :rows="hospitalsOfSelectedArea.length>10?10:hospitalsOfSelectedArea.length"
                          v-model="hospitalsOfSelectedAreaStr" disabled></el-input>
            </div>
            <div class="survey_content_line nurse_content_line">
                <div class="nurse_label">专员名字</div>
                <el-input class="nurse_value" v-model="nurse.name"></el-input>
            </div>
            <div class="survey_content_line nurse_content_line">
                <div class="nurse_label">联系方式</div>
                <el-input class="nurse_value" :disabled="nurseId!==undefined" v-model="nurse.phone"></el-input>
            </div>
            <div class="survey_content_line nurse_content_line">
                <div class="nurse_label">专员工作城市</div>
                <el-input class="nurse_value" v-model="nurse.workCity"></el-input>
            </div>
            <div class="survey_content_line nurse_content_line">
                <div class="nurse_label">专员上岗时间</div>
                <el-date-picker class="nurse_value"
                        v-model="nurse.startDate"
                        type="date">
                </el-date-picker>
            </div>
            <div class="survey_content_line nurse_content_line">
                <div class="nurse_label">专员离职时间</div>
                <el-date-picker class="nurse_value"
                        v-model="nurse.endDate"
                        type="date">
                </el-date-picker>
            </div>
            <div class="survey_content_line nurse_content_line">
                <div class="nurse_label">流动情况</div>
                <el-input class="nurse_value" v-model="nurse.ldqk"></el-input>
            </div>
            <div class="survey_content_line nurse_content_line">
                <div class="nurse_label">是否有项目经验</div>

                <el-switch class="nurse_value"
                        v-model="nurse.xmjy"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                </el-switch>
            </div>
            <div class="save-btn-wrapper">
                <nh-button class="a-bottom" @click="doSave">保存</nh-button>
            </div>
        </div>
    </div>
</template>

<script>
import NhTitle from "@/components/patient/components/nh-title";
import NhButton from "@/components/patient/components/nh-button";
export default {
    name: "NurseEdit",
    components: {NhButton, NhTitle},
    data() {
        return {
            nurseId: undefined,
            nurse: {
                areaIds: []
            },
            areaList: [],
            hospitalsOfSelectedArea: []
        }
    },
    computed: {
        hospitalsOfSelectedAreaStr() {
            return this.hospitalsOfSelectedArea.join('\n')
        }
    },
    watch: {},
    created() {
        this.nurseId = this.$route.query.id
        this.getData()
    },
    methods: {
        getData() {
            if(this.nurseId) {
                this.$httpUtil.get(this.$urlConstant.nuohua.nurseGetPutDel + this.nurseId,res=>{
                    this.nurse = res.data
                    this.fetchAreas()
                },this)
            }else {
                this.fetchAreas()
            }
        },
        fetchAreas() {
            this.$httpUtil.get(this.$urlConstant.nuohua.areaList,(res)=>{
                this.areaList = res.data
                this.doChange(this.nurse.areaIds)
            }, this)
        },
        doSave() {
            if(!this.nurse.name) {
                this.$message.error('请输入名字')
                return
            }
            if(!this.nurse.areaIds || this.nurse.areaIds.length <= 0) {
                this.$message.error('请输入区域')
                return
            }
            if(!this.nurse.phone) {
                this.$message.error('请输入电话')
                return
            }
            const result = !isNaN(this.nurse.phone) && this.nurse.phone.startsWith(1)
                && this.nurse.phone.length === 11
            if (!result) {
                this.$message.error('电话格式不正确')
                return false
            }

            this.nurse.hospitals = this.hospitalsOfSelectedArea

            if(this.nurseId) {
                this.$httpUtil.put(this.$urlConstant.nuohua.nurseGetPutDel+this.nurseId, this.nurse,()=>{
                    this.$message.success('保存成功')
                    setTimeout(() => {
                        this.$router.replace({name: 'NurseList'})
                    }, 1000)
                },this)
            }else {
                this.$httpUtil.post(this.$urlConstant.nuohua.nurseCreate, this.nurse,()=>{
                    this.$message.success('保存成功')
                    setTimeout(() => {
                        this.$router.replace({name: 'NurseList'})
                    }, 1000)
                },this)
            }
        },
        doChange(selectedValues) {
            console.log(selectedValues)
            this.hospitalsOfSelectedArea = []
            if (selectedValues) {
                for (const selectedValue of selectedValues) {
                    const selectedArea = this.areaList.find(area => area.id === selectedValue)
                    console.log(selectedArea)
                    if (selectedArea) {
                        for (const hospital of selectedArea.hospitalList) {
                            if (!this.hospitalsOfSelectedArea.includes(hospital))
                                this.hospitalsOfSelectedArea.push(hospital)
                        }
                    }
                }
            }
        }
    },
}
</script>

<style scoped>
.nurse_content_line {
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    min-height: 40px;
}
.nurse_label {
    width: 100px;
    border-right: 1px solid #DCDFE6;
}
.nurse_value {
    flex: 1;
}
.nurse_value >>> .el-input__inner {
    border: unset;
}
</style>